<template>
    <f7-popup ref="popupprobarauditoria" tablet-fullscreen :opened="true" @popup:closed="onClose">
        <f7-page>
            <f7-navbar>
                <f7-nav-left>
                    <f7-link popup-close>{{$t('cerrar')}}</f7-link>
                </f7-nav-left>
                <f7-nav-title>
                    {{$t('Probar auditoría')}}
                </f7-nav-title>
                <f7-nav-right>
                
                </f7-nav-right>
                <f7-subnavbar>
                    <f7-segmented raised class="elevation-6">
                        <f7-button fill tab-link="#tab-instalacion" tab-link-active @click="tabActive = 'Instalacion';">Instalación</f7-button>
                        <f7-button fill tab-link="#tab-comprobaciones" @click="tabActive = 'TomaDatos';">Toma de datos</f7-button>
                        <f7-button fill tab-link="#tab-valores" @click="tabActive = 'Valores';">Valores</f7-button>
                    </f7-segmented>
                </f7-subnavbar>
            </f7-navbar>

            <r-form
                v-if="formato"
                ref="rform"
                :readonly="false"
                class="page"
                classContent="full-height page-content"
                :initialData="formato"
            >
                <template v-slot:default="{ rform }">
                    <f7-tab  v-if="tabActive == 'Instalacion'" id="tab-instalacion" tab-active class="page-content">
                        <!-- Características -->
                            <div
                                class="textoAzul w-99"
                                v-for="(caracteristica, ic) in formato.caracteristicas"
                                :key="'caracteristica-' + caracteristica.nombre + '-' + ic"
                            >
                                <caracteristica
                                    :key="'caract-' + caracteristica.nombre + '-' + ic"
                                    :tipo="caracteristica.tipo"
                                    :caracteristica="caracteristica"
                                    :form="rform"
                                    :label="caracteristica.label"
                                    :name="'caracteristicas.' + ic"
                                    :allCaracteristicas="formato.caracteristicas"
                                    @change="caracteristicaModificada($event, 'CARACTERISTICA')"
                                ></caracteristica>
                            </div>  
                    </f7-tab>
                    <f7-tab v-if="tabActive == 'TomaDatos'" tab-active id="tab-comprobaciones" class="page-content">
                        <!-- Características -->
                            <f7-list accordion-list>
                                <comprobacion
                                    v-for="(comprobacion, ic) in formato.comprobaciones"
                                    :key="'comprobaciones.' + comprobacion.codigo"
                                    :comprobacion="comprobacion"
                                    :name="'comprobaciones.' + ic"
                                    :referencia="'comprobaciones_' + ic"
                                    :form="rform"
                                    :resultadoDefecto="comprobacion.valorInicial"
                                    @changeChild="caracteristicaModificada($event, 'COMPROBACION')"
                                ></comprobacion>
                            </f7-list> 
                    </f7-tab>
                    <f7-tab v-if="tabActive == 'Valores'" tab-active id="tab-valores" class="page-content">
                        <!-- Valores -->
                            <f7-list accordion-list>
                                <div v-for="(valor, ic) in formato.auditoriaGPT.valores"
                                    :key="'valores.' + ic">
                                    <r-input
                                        outline
                                        :form="rform"
                                        :name="'valores.' + valor.nombre"
                                        @change="caracteristicaModificada({ nombre: valor.nombre, valor: $event}, 'VALOR')"
                                    >
                                    <slot name="label" slot="label">
                                        {{ 'Valor ' + valor.nombre }} 
                                        <!-- {{ formato.erroresAuditoria }} -->
                                        <div v-tooltip="formato.erroresAuditoria.filter(e => e.valor == valor.nombre).flatMap(e => e.errores).map(e => e.error).join('<br/>')" v-if="formato.erroresAuditoria.filter(e => e.valor == valor.nombre) && formato.erroresAuditoria.filter(e => e.valor == valor.nombre).length" style="color: red;">{{ formato.erroresAuditoria.filter(e => e.valor == valor.nombre).flatMap(e => e.errores).map(e => e.error).join('; ') }}</div>
                                    </slot>
                                    </r-input>
                                </div>
                            </f7-list> 
                    </f7-tab>
                </template>
            </r-form>
        </f7-page>
    </f7-popup>
</template>

<style scoped>

</style>

<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import Caracteristica from "./../inspecciones/Caracteristica.vue";
import Comprobacion from "./../inspecciones/Comprobacion.vue";

export default {
    components: {
        rForm,
        rInput,
        Caracteristica,
        Comprobacion,
    },
    data: function () {
        var self = this;
        return {
            tabActive: 'Instalacion',
            formato: null
        };
    },
    props: {
        formatoOrigen: Object
    },
    computed: {},
    mounted() {
        const self = this;
        self.formato = JSON.parse(JSON.stringify(self.formatoOrigen));
        self.getCaracteristicasHijas(self.formato.caracteristicas).forEach(c => {
            if (!c.erroresAuditoria) {
                self.$set(c, 'erroresAuditoria', []);
            }
            if (c.valor == null || c.valor == undefined) {
                self.$set(c, 'valor', '');
            }
        })
    },
    beforeDestroy() { },
    methods: {
        onClose: function () {
            const self = this;
            self.$emit('close');
        },
        caracteristicaModificada(caracteristica, tipo) {
            const self = this;
            console.log(tipo + " modif", caracteristica);
            if (self.formato.auditoriaGPT.caracteristicas || self.formato.auditoriaGPT.comprobaciones || self.formato.auditoriaGPT.valores) {
                let caractsAfectadas = [];
                if (tipo == 'CARACTERISTICA') {
                    caractsAfectadas = [
                        ...self.formato.auditoriaGPT.caracteristicas.filter(c => c.caracteristicasAfectadas && c.caracteristicasAfectadas.includes(caracteristica.nombre)),
                        ...self.formato.auditoriaGPT.comprobaciones.filter(c => c.caracteristicasAfectadas && c.caracteristicasAfectadas.includes(caracteristica.nombre)),
                        ...self.formato.auditoriaGPT.valores.filter(c => c.caracteristicasAfectadas && c.caracteristicasAfectadas.includes(caracteristica.nombre)),
                    ];
                } else if (tipo == 'COMPROBACION') {
                    caractsAfectadas = [
                        ...self.formato.auditoriaGPT.caracteristicas.filter(c => c.comprobacionesAfectadas && c.comprobacionesAfectadas.includes(caracteristica.codigo)),
                        ...self.formato.auditoriaGPT.comprobaciones.filter(c => c.comprobacionesAfectadas && c.comprobacionesAfectadas.includes(caracteristica.codigo)),
                        ...self.formato.auditoriaGPT.valores.filter(c => c.comprobacionesAfectadas && c.comprobacionesAfectadas.includes(caracteristica.codigo))
                    ];
                } else if (tipo == 'VALOR') {
                    caractsAfectadas = [
                        ...self.formato.auditoriaGPT.caracteristicas.filter(c => c.valoresAfectados && c.valoresAfectados.includes(caracteristica.nombre)),
                        ...self.formato.auditoriaGPT.comprobaciones.filter(c => c.valoresAfectados && c.valoresAfectados.includes(caracteristica.nombre)),
                        ...self.formato.auditoriaGPT.valores.filter(c => c.valoresAfectados && c.valoresAfectados.includes(caracteristica.nombre))
                    ];
                }
                const caracts = self.getCaracteristicasHijas(self.formato.caracteristicas);
                const compros = self.getCaracteristicasHijas(self.formato.comprobaciones);
                if (!caracteristica.erroresAuditoria) {
                    self.$set(caracteristica, 'erroresAuditoria', []);
                }
                if (!self.formato.erroresAuditoria) {
                    self.$set(self.formato, 'erroresAuditoria', []);
                }
                // const expediente = Object.assign(self.formato.valores || {}, { caracteristicas: caracts, comprobaciones: self.formato.comprobaciones });
                const expediente = {
                    ...self.$refs.rform.formData.valores,
                    caracteristicas: caracts,
                    comprobaciones: compros
                };
                let erroresValor = { errores: [] };
                if (tipo == 'VALOR') {
                    erroresValor = self.formato.erroresAuditoria.filter(err => err.valor == caracteristica.nombre)[0];
                    if (!erroresValor) {
                        self.formato.erroresAuditoria.push({ valor: caracteristica.nombre, errores: [] });
                        erroresValor = self.formato.erroresAuditoria.filter(err => err.valor == caracteristica.nombre)[0];
                    }
                    if (!erroresValor.errores) {
                        erroresValor.errores = [];
                    }
                }
                caractsAfectadas.forEach(c => {
                    const caractsErrores = [
                        ...caracts.filter(c2 => c2.erroresAuditoria && c2.erroresAuditoria.find(e => e.nombre == (c.codigo || c.nombre))),
                        ...compros.filter(c2 => c2.erroresAuditoria && c2.erroresAuditoria.find(e => e.nombre == (c.codigo || c.nombre))),
                        ...self.formato.erroresAuditoria.filter(e => e.errores && e.errores.find(e2 => e2.nombre == (c.codigo || c.nombre)))
                    ];
                    // const erroresValores = self.formato.erroresAuditoria.filter(err => err.valor == (c.codigo || c.nombre));
                    const condition = c.regla;
                    let error = caracteristica.erroresAuditoria.filter(e => e.nombre == (c.codigo || c.nombre))[0];
                    if (tipo == 'VALOR') {
                        error = erroresValor.errores.filter(e => e.nombre == (c.codigo || c.nombre))[0];
                    }
                    try {
                        const res = eval(condition);
                        if (typeof res != 'boolean' || !res) {
                            if (error) {
                                error.error = res;
                            } else {
                                if (tipo == 'VALOR') {
                                    erroresValor.errores.push({ nombre: (c.codigo || c.nombre), error: res });
                                } else {
                                    caracteristica.erroresAuditoria.push({ nombre: (c.codigo || c.nombre), error: res })
                                }
                            }
                            if ((c.codigo || c.nombre) != (caracteristica.codigo || caracteristica.nombre)) {
                                let ca2 = caracts.find(ca => c.nombre && ca.nombre == c.nombre);
                                let esValor = false;
                                if (!ca2) {
                                    ca2 = compros.find(ca => c.codigo && ca.codigo == c.codigo);
                                }
                                if (!ca2) {
                                    ca2 = self.formato.auditoriaGPT.valores.find(v => v.nombre == c.nombre);
                                    esValor = true;
                                }
                                self.addErrorAuditoria(ca2, (c.codigo || c.nombre), res, esValor);
                            } else {
                                c.caracteristicasAfectadas.forEach(caf => {
                                    if (caf != (c.codigo || c.nombre)) {
                                        let ca2 = caracts.find(ca => ca.nombre == caf);
                                        // let esValor = false;
                                        // if (!ca2) {
                                        //     ca2 = compros.find(ca => ca.codigo == caf);
                                        // }
                                        // if (!ca2) {
                                        //     ca2 = self.formato.auditoriaGPT.valores.find(v => v.nombre == c.nombre);
                                        //     esValor = true;
                                        // }
                                        self.addErrorAuditoria(ca2, (c.codigo || c.nombre), res, false);
                                    }
                                });
                                c.comprobacionesAfectadas.forEach(caf => {
                                    if (caf != (c.codigo || c.nombre)) {
                                        let ca2 = compros.find(ca => ca.codigo == caf);
                                        // let ca2 = caracts.find(ca => ca.nombre == caf);
                                        // let esValor = false;
                                        // if (!ca2) {
                                        //     ca2 = compros.find(ca => ca.codigo == caf);
                                        // }
                                        // if (!ca2) {
                                        //     ca2 = self.formato.auditoriaGPT.valores.find(v => v.nombre == c.nombre);
                                        //     esValor = true;
                                        // }
                                        self.addErrorAuditoria(ca2, (c.codigo || c.nombre), res, false);
                                    }
                                });
                                c.valoresAfectados.forEach(vaf => {
                                    if (vaf != (c.codigo || c.nombre)) {
                                        let ca2 = self.formato.auditoriaGPT.valores.find(v => v.nombre == vaf);
                                        // let ca2 = caracts.find(ca => ca.nombre == vaf);
                                        // let esValor = false;
                                        // if (!ca2) {
                                        //     ca2 = compros.find(ca => ca.codigo == vaf);
                                        // }
                                        // if (!ca2) {
                                        //     ca2 = self.formato.auditoriaGPT.valores.find(v => v.nombre == vaf);
                                        //     esValor = true;
                                        // }
                                        self.addErrorAuditoria(ca2, (c.codigo || c.nombre), res, true);
                                    }
                                });
                            }
                        } else {
                            self.removeErrors(error, caracteristica, caractsErrores);
                        }
                    } catch (e) {
                        console.log(e);
                        self.removeErrors(error, caracteristica, caractsErrores);
                    }
                });
            }
        },
        addErrorAuditoria(ca2, nombre, strError, esValor = false) {
            const self = this;
            if (ca2) {
                if (esValor) {
                    let errV = self.formato.erroresAuditoria.filter(e => e.valor == ca2.nombre)[0];
                    if (!errV) {
                        self.formato.erroresAuditoria.push({ valor: ca2.nombre, errores: [] });
                        errV = self.formato.erroresAuditoria.filter(e => e.valor == ca2.nombre)[0];
                    }
                    const error2 = errV.errores.filter(e => e.nombre == nombre)[0];
                    if (error2) {
                        error2.error = strError;
                    } else {
                        errV.errores.push({ nombre: nombre, error: strError });
                    }

                } else {
                    if (!ca2.erroresAuditoria) {
                        self.$set(ca2, 'erroresAuditoria', []);
                    }
                    const error2 = ca2.erroresAuditoria.filter(e => e.nombre == nombre)[0];
                    if (error2) {
                        error2.error = strError;
                    } else {
                        ca2.erroresAuditoria.push({ nombre: nombre, error: strError });
                    }
                }
            }
        },
        removeErrors(error, caracteristica, caractsErrores) {
            const self = this;
            if (error) {
                let idx = caracteristica.erroresAuditoria.indexOf(error);
                if (idx > -1) {
                    caracteristica.erroresAuditoria.splice(idx, 1);
                } else {
                    let error2 = self.formato.erroresAuditoria.filter(e => e.valor == caracteristica.nombre)[0];
                    if (error2) {
                        let idxE = error2.errores.indexOf(error);
                        if (idxE > -1) {
                            error2.errores.splice(idxE, 1);
                        }
                    }
                }
            }
            caractsErrores.forEach(cError => {
                if (cError.erroresAuditoria) {
                    const err = cError.erroresAuditoria.find(e => e.nombre == (error ? error.nombre : (caracteristica.codigo || caracteristica.nombre)));
                    if (err) {
                        cError.erroresAuditoria.splice(cError.erroresAuditoria.indexOf(err), 1);
                    }
                } else if (cError.errores) {
                    const err = cError.errores.find(e => e.nombre == (error ? error.nombre : (caracteristica.codigo || caracteristica.nombre)));
                    if (err) {
                        cError.errores.splice(cError.errores.indexOf(err), 1);
                    }
                }
            });
            // if (erroresValores) {
            //     erroresValores.forEach(err => {
            //         self.formato.erroresAuditoria.splice(self.formato.erroresAuditoria.indexOf(err), 1);
            //     });
            // }
        },
        getCaracteristicasHijas: function (caracteristicas) {
            const self = this;
            let caracts = [];
            caracteristicas.forEach(c => {
                if (c.hijas && c.hijas.length) {
                    const hijas = self.getCaracteristicasHijas(c.hijas);
                    caracts = caracts.concat(hijas);
                } else {
                    caracts.push(c);
                }
            })
            return caracts;
        }
    }
}
</script>
